import "./App.css";
import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import useTheme from "./hooks/useTheme";
import Loader from "./components/Loader";

function App() {
  const { getTheme } = useTheme();

  return (
    <div className="Weddy">
      <Suspense fallback={<Loader />}>{getTheme()}</Suspense>
    </div>
  );
}

export default App;
