import React from "react";
import styles from "./index.module.scss";
import Photo from "./flower.webp";

const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <img src={Photo} alt="" />
      <h1>Invitation Not Found</h1>
      <p>
        We couldn't find the invitation you were looking for. Can you check
        invitation url?
      </p>
    </div>
  );
};

export default NotFound;
