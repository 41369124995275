import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Themes } from "../../constants/theme";
import Loader from "../../components/Loader";

const FirstInvitation = React.lazy(() =>
  import("../../themes/FirstInvitation")
);
const SecondInvitation = React.lazy(() =>
  import("../../themes/SecondInvitation")
);
const ThirdInvitation = React.lazy(() =>
  import("../../themes/ThirdInvitation")
);
const FourthInvitation = React.lazy(() =>
  import("../../themes/FourthInvitation")
);

const NotFound = React.lazy(() => import("../../components/NotFound"));

const useTheme = () => {
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const param = useParams();

  useEffect(() => {
    axios
      .get(`/api/${param.id}.json`)
      .then((res) => {
        document.title = `${res.data.groomName} & ${res.data.brideName} Wedding`;

        setInfo(res.data);
      })
      .catch(() => {
        document.title = "Not Found";
      })
      .finally(() => setLoading(false));
  }, [param.id]);

  const getTheme = () => {
    switch (info?.theme) {
      case Themes.FirstInvitation:
        return <FirstInvitation info={info} />;
      case Themes.SecondInvitation:
        return <SecondInvitation info={info} />;
      case Themes.ThirdInvitation:
        return <ThirdInvitation info={info} />;
      case Themes.FourthInvitation:
        return <FourthInvitation info={info} />;
      default:
        return loading ? <Loader /> : <NotFound />;
    }
  };

  return { getTheme };
};

export default useTheme;
